import { Button, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { BsLockFill } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatterNumber } from "../utils/input-number";
import { useGetProfile } from "../UserProfileContext";
import moment from "moment";
import { getAll } from "../utils/service";
import { API_URL } from "../api/config";

function BlockPanel() {
  const [modalLock, setModalLock] = useState(false);
  const history = useHistory();
  const dataProfile = useGetProfile();
  const [subsPlan, setSubsPlan] = useState(null);
  const getSubsPlan = async () => {
    var res = await getAll(API_URL.panel + "/subscription-plan");
    if (res?.status === 200) {
      setSubsPlan(res.data.data);
    }
  };

  useEffect(() => {
    var res = moment(dataProfile?.panel?.expired_date).isBefore(moment());
    if (res) {
      getSubsPlan();
    }
    setModalLock(res);
  }, [dataProfile]);

  return (
    <Modal
      title={
        <h4 className="vc-mb-0 vc-text-center vc-text-color-primary-1">
          <BsLockFill /> Akses Dibatasi
        </h4>
      }
      open={modalLock}
      closable={false}
      footer={null}
    >
      <p className="vc-text-center vc-p-0 vc-m-0">
        Segera topup saldo Mitra untuk memperpanjang masa penggunaan panel dan
        website topup
      </p>
      <div className="vc-px-16 vc-py-8 vc-bg-info-4 vc-my-16 vc-d-flex vc-justify-content-between vc-w-100 vc-border-radius">
        <p className="vc-w-100 vc-p-0 vc-m-0">Biaya Perpanjangan</p>
        <p className="vc-w-100 vc-text-right vc-p-0 vc-m-0 vc-font-weight-600 vc-text-color-info-1">
          Rp {formatterNumber(subsPlan?.price)}
        </p>
      </div>
      <p className="vc-text-center">
        Saldo Mitra yang cukup akan otomatis terpotong untuk perpanjangan
      </p>
      <Button
        type="primary"
        className="vc-w-100"
        onClick={() => {
          setModalLock(false);
          history.push("/topup/data");
        }}
      >
        Top Up Saldo Mitra
      </Button>
      <p
        className="vc-mt-16 vc-cursor-pointer vc-text-color-primary-1 vc-text-center"
        onClick={() => history.push("/panel/statistic")}
      >
        Kembali ke Dashboard
      </p>
    </Modal>
  );
}

export default BlockPanel;
