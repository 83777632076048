import React from "react";
import { Link } from "react-router-dom";

import { Divider, Row, Col } from "antd";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  RiShoppingCart2Line,
  RiStore3Line,
  RiComputerLine,
} from "react-icons/ri";
import { useGetProfile } from "../../../../UserProfileContext";

export default function MenuFooter(props) {
  const location = useLocation();
  const handleSwitchMode = (mode) => {
    if (mode === "panel") {
      window.location.href = "/panel/statistic";
      localStorage.setItem("vcg-sidebar-type", "panel");
    } else {
      window.location.href = "/dashboard";
      localStorage.setItem("vcg-sidebar-type", "mitra");
    }
  };

  const dataProfile = useGetProfile();

  return (
    <Row
      className="vc-sidebar-footer vc-pb-24 vc-px-24 vc-bg-color-dark-100 vc-py-16"
      align="middle"
      justify="space-between"
      style={{ backgroundColor: "#7750F7A" }}
    >
      <Col span={24}>
        {location.pathname.startsWith("/panel") ? (
          <>
            <div
              className="vc-border-1 vc-mb-8 vc-p-8 vc-d-flex vc-d-flex-justify-center vc-bg-color-primary-1 vc-align-items-center vc-cursor-pointer"
              style={{ borderRadius: "8px" }}
              id="vc-panel-link"
              onClick={() => (window.location.href = dataProfile?.panel?.url)}
            >
              <RiComputerLine className="remix-icon vc-text-color-primary-4" />
              {!props.collapsed && (
                <div
                  className="vc-text-color-primary-4 vc-mt-2 vc-ml-4"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  Buka Website
                </div>
              )}
            </div>
            <div
              className="vc-border-1 vc-border-color-primary-1 vc-p-8 vc-d-flex vc-d-flex-justify-center vc-bg-color-primary-4 vc-align-items-center vc-cursor-pointer"
              style={{ borderRadius: "8px" }}
              onClick={() => handleSwitchMode("mitra")}
            >
              <RiStore3Line className="remix-icon vc-text-color-primary-1" />
              {!props.collapsed && (
                <div
                  className="vc-text-color-primary-1 vc-mt-2 vc-ml-4"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  Kembali ke H2H
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className="vc-border-1 vc-border-color-primary-1 vc-p-8 vc-d-flex vc-d-flex-justify-center vc-bg-color-primary-4 vc-align-items-center vc-cursor-pointer"
              style={{ borderRadius: "8px" }}
              onClick={() => handleSwitchMode("panel")}
            >
              <RiShoppingCart2Line className="remix-icon vc-text-color-primary-1" />
              {!props.collapsed && (
                <div
                  className="vc-text-color-primary-1 vc-mt-2 vc-ml-4"
                  style={{ fontSize: "14px" }}
                >
                  {" "}
                  Buka Panel Website
                </div>
              )}
            </div>
            <div
              style={{ fontWeight: 600, fontSize: "12px" }}
              className="vc-text-color-dark-40 vc-text-center vc-mt-6"
            >
              Website:{" "}
              <strong className="vc-text-color-success-1">Aktif</strong>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
}
