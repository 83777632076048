import React, { useMemo } from "react";
import { useGetProfile } from "../../../../UserProfileContext";
import moment from "moment";
import { Alert, Button, Col, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";

function ExpiredAlert({ isHeader = false }) {
  const dataProfile = useGetProfile();
  const location = useLocation();

  const expiredDate = dataProfile?.panel?.expired_date;

  // Exit if no expiration date
  if (!expiredDate) return null;

  // Check if the expiration date is within the last 20 days
  const isAfter20 = useMemo(() => {
    return moment().isAfter(moment(expiredDate).subtract(20, "days"));
  }, [expiredDate]);

  // Check if the domain is expired
  const isExpired = useMemo(() => {
    return moment().isAfter(moment(expiredDate));
  }, [expiredDate]);

  // If not expired and not in the last 30 days, only show for non-header cases
  if (!isAfter20 && !isHeader)
    return (
      <Row>
        <Col span={24}>
          <Alert
            description={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="vc-mb-0 vc-pr-16">
                  <span className="vc-text-color-black-100">
                    Masa aktif domain s.d{" "}
                  </span>
                  <span className="vc-font-weight-600">
                    {moment(expiredDate).format("DD MMMM YYYY HH:mm")}
                  </span>
                </p>
              </div>
            }
            type="success"
            className="vc-w-100"
          />
        </Col>
      </Row>
    );

  // Show alert when the domain is expired or close to expiring
  if (
    isAfter20 &&
    ((location.pathname !== "/panel/statistic" && isHeader) ||
      (location.pathname === "/panel/statistic" && !isHeader))
  )
    return (
      <Row>
        <Col span={24}>
          <Alert
            closable={isHeader}
            closeIcon={
              isHeader && (
                <RiCloseLine size={16} className="vc-text-color-primary-1" />
              )
            }
            description={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="vc-mb-0 vc-pr-16">
                  {isExpired ? (
                    <>
                      <span className="vc-font-weight-600">
                        URL Domain Expired.
                      </span>{" "}
                      <span className="vc-text-color-black-100">
                        Segera lakukan perpanjangan domain untuk membuka fitur
                        panel dan berjualan di website topup. Info selengkapnya
                        hubungi{" "}
                      </span>
                      <span className="vc-text-color-primary-1 vc-font-weight-600">
                        Customer Support kami
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="vc-text-color-black-100">
                        URL domainmu expired pada{" "}
                      </span>
                      <span className="vc-font-weight-600">
                        {moment(expiredDate).format("DD MMMM YYYY HH:mm")}
                      </span>{" "}
                      <span className="vc-text-color-black-100">
                        Perpanjangan domain diambil otomatis dari Saldo Mitra.
                        Segera lakukan perpanjangan domain agar kamu bisa tetap
                        berjualan di website topup. Info selengkapnya hubungi{" "}
                      </span>
                      <span className="vc-text-color-primary-1 vc-font-weight-600">
                        Customer Support kami
                      </span>
                    </>
                  )}
                </p>
                <div>
                  <Link to="/topup/data">
                    <Button
                      type="primary"
                      className="vc-w-100 vc-text-wr"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Top Up Saldo
                    </Button>
                  </Link>
                </div>
              </div>
            }
            type="error"
            className="vc-w-100"
            style={{ padding: "8px 24px" }}
          />
        </Col>
      </Row>
    );

  return null;
}

export default ExpiredAlert;
