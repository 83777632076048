import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import ModalOnboarding from "./components/onboarding";
import FloatingWhatsapp from "./components/whatsapp";
import { BrowserView, MobileView } from "react-device-detect";
import BottomMenu from "./components/content/bottom-menu";
import MenuFooter from "./components/footer";
import { UserProfileProvider, useGetProfile } from "../UserProfileContext";
import AppBanner from "./components/content/app-banner";
import ExpiredAlert from "./components/content/panel/ExpiredAlert";

const { Content } = Layout;

export default function VerticalLayout(props) {
  const { children } = props;

  // Redux
  const customise = useSelector((state) => state.customise);

  // Profile
  const dataProfile = useGetProfile();

  const [visible, setVisible] = useState(false);

  const [webSocket, setWebSocket] = useState(null);

  const [appBannerShow, setAppBannerShow] = useState(true);
  const [userHasPWA, setUserHasPWA] = useState(false);

  function isPWA() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) =>
        window.matchMedia("(display-mode: " + displayMode + ")").matches
    );
  }
  useEffect(() => {
    if (isPWA()) {
      setUserHasPWA(true);
      setAppBannerShow(false);
    } else {
      setUserHasPWA(false);
      setAppBannerShow(true);
    }
  }, []);

  return (
    <UserProfileProvider>
      <BrowserView>
        <ExpiredAlert isHeader={true} />
        <Layout className="vc-app-layout">
          <Sidebar visible={visible} setVisible={setVisible} />

          <Layout className="vc-bg-color-dark-90">
            <MenuHeader setVisible={setVisible} className="vc-d-none" />

            <Content className="vc-content-main">
              <Row justify="center">
                <Col span={24}>{children}</Col>
              </Row>
            </Content>

            <ModalOnboarding type="modal" />
            {dataProfile &&
              dataProfile?.tier_has_panel &&
              dataProfile.panel !== null && <MenuFooter />}
          </Layout>

          <FloatingWhatsapp />
        </Layout>
      </BrowserView>
      <MobileView>
        <Layout>
          <Layout className="vc-bg-color-dark-90">
            <Content>
              <Row justify="center">
                <Col
                  span={24}
                  className={
                    appBannerShow === true && userHasPWA === false
                      ? "vc-wmr-layout vc-wmr-layout-non-pwa vc-bg-color-dark-90"
                      : "vc-wmr-layout vc-bg-color-dark-90"
                  }
                >
                  {userHasPWA === false && (
                    <AppBanner onClose={() => setAppBannerShow(false)} />
                  )}

                  {children}
                </Col>
              </Row>
            </Content>
            <ModalOnboarding type="drawer" />
          </Layout>
        </Layout>
      </MobileView>
    </UserProfileProvider>
  );
}
