import React, { useEffect, useRef, useState } from "react";

import { Row, Col, Progress, Modal, Card, message } from "antd";
import { RiCloseLine, RiCheckLine, RiArrowRightSLine } from "react-icons/ri";

import { useHistory, useLocation } from "react-router-dom";
import { useGetProfile } from "../UserProfileContext";
import { getAll } from "../utils/service";
import { api_base_url, API_URL_PANEL } from "../api/config";

export default function ProgressModal({ open, onClose }) {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [currentState, setCurrentState] = useState("mitra");

  const dataProfile = useGetProfile();

  const [progress, setProgress] = useState(0);

  // panel status
  const [panelStatus, setPanelStatus] = useState();
  const getPanelProgress = async () => {
    var res = await getAll(API_URL_PANEL.status);
    if (res?.status === 200) {
      setPanelStatus(res?.data?.data);
    }
  };

  useEffect(() => {
    getPanelProgress();
  }, [location]);

  const mitraProgress = [
    {
      text: "Pendaftaran Akun",
      status: true,
    },
    {
      text: "Buat PIN",
      status: dataProfile?.has_pin,
      link: "/setting/pin",
    },
    {
      text: "Lengkapi Berkas",
      status: dataProfile?.kyc_verified,
      link: "/kyc",
    },
    {
      text: "Top Up Saldo Pertama",
      status: dataProfile?.has_topup,
      link: "/topup/form",
    },
    {
      text: "Beli Produk Pertamamu",
      status: dataProfile?.has_purchase,
      link: "/product/data",
    },
  ];

  const panelProgress = [
    {
      text: "Aktifkan Notifikasi",
      status: Notification.permission === "granted",
      description: "Jangan lewatkan notifkasi pesanan dan lainnya",
      action: () => {
        var isGranted = Notification.permission === "granted";
        Notification.requestPermission().then(() => {
          message.success("Notifikasi diaktifkan");
          if (isGranted) window.location.reload();
        });
      },
    },
    {
      text: "Atur Tier Member",
      status: panelStatus?.is_tier_set,
      link: "/panel/setting/member-tier/data",
      description: "untuk mendapatkan perhitungan harga jual",
    },
    {
      text: "Tambah Produk",
      status: panelStatus?.is_product_set,
      link: "/panel/product-vcg/data",
      description: "Aktifkan produk yang ingin dijual",
    },
    {
      text: "Lengkapi Kontak",
      status: panelStatus?.is_profile_set,
      link: "/panel/setting/social-media",
      description: "Untuk memudahkan customer menghubungi kamu",
    },
  ];

  const [list, setList] = useState([]);

  useEffect(() => {
    var progress = mitraProgress;
    var state = "mitra";
    if (location.pathname.includes("/panel")) {
      progress = panelProgress;
      state = "panel";
    }

    var items = progress.map((item) => item.status);
    var total = (items.filter((item) => item).length / items.length) * 100;

    setCurrentState(state);
    setProgress(total);
    setList(progress);
  }, [location, dataProfile, panelStatus]);

  const toLink = (link) => {
    history.push(link);
    if (onClose) onClose();
  };

  return (
    <Modal
      open={open}
      title={`Persiapan Akun ${
        pathname.includes("/panel") ? "Panel" : "Mitra"
      } Kamu`}
      footer={null}
      onCancel={() => {
        if (onClose) onClose();
        if (progress == 100)
          localStorage.setItem("modal-progress-" + currentState, false);
      }}
      cancelText="Sembunyikan"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
        className="vc-mb-32"
      >
        <Progress
          width="150px"
          type="circle"
          percent={progress}
          showInfo={false}
          strokeColor={{
            "0%": progress == 100 ? "#0f0" : "#f00",
            "100%": progress == 100 ? "#0f0" : "#f00",
          }}
        />
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "150px",
            height: "168px",
          }}
        >
          <h2>{progress}%</h2>
        </div>
      </div>
      {list.map((item, index) => (
        <Card
          className={
            "vc-mb-8 " + (item.link || item.action ? "vc-cursor-pointer" : "")
          }
          bodyStyle={{ padding: "8px 16px" }}
          onClick={() => {
            if (item.link) return toLink(item.link);
            if (item.action) return item.action();
          }}
        >
          <Row align={"middle"} justify={"space-between"}>
            <Col>
              <p className="vc-mb-0">{item.text}</p>
              <p
                className="vc-mb-0 vc-font-weight-400"
                style={{ fontSize: 12 }}
              >
                {item?.description}
              </p>
            </Col>
            <Col>
              <Row gutter={8} justify={"start"}>
                <Col span={12}>
                  {item.status ? (
                    <RiCheckLine
                      size={18}
                      className="vc-text-color-success-1"
                    />
                  ) : (
                    <RiCloseLine size={18} className="vc-text-color-danger-1" />
                  )}
                </Col>
                <Col span={12}>
                  {item.status ? (
                    <div style={{ width: "18px" }}></div>
                  ) : (
                    <RiArrowRightSLine
                      size={18}
                      className="vc-text-color-black-60"
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      ))}
    </Modal>
  );
}
