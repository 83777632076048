import { Button, Col, Row } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Buy } from "react-iconly";

function MenuHeader(props) {
  const location = useLocation();
  const { pathname } = location;

  if (pathname.includes("/panel")) return null;
  return (
    <Row className="vc-w-100">
      <Col span={24} className="vc-w-100 vc-px-24">
        <Link
          to="/product/data"
          className="vc-w-100 vc-p-6 vc-d-flex vc-d-flex-justify-center vc-bg-color-primary-1 vc-align-items-center vc-cursor-pointer"
          style={{ borderRadius: "8px" }}
        >
          <Buy
            size={"small"}
            className="remix-icon"
            style={{ color: "white" }}
          />
          {!props.collapsed && (
            <div
              className="vc-text-color-primary-4 vc-mt-2 vc-ml-4"
              style={{ color: "white" }}
            >
              {" "}
              Beli Produk
            </div>
          )}
        </Link>
      </Col>
    </Row>
  );
}

export default MenuHeader;
