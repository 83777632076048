import React, { useEffect, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Badge, Button, Col, Menu, Modal, Row } from "antd";

import navigation from "../../../../navigation/mitra";
import navigationPanel from "../../../../navigation/panel";
import { API_URL } from "../../../../api/config";
import { getAll } from "../../../../utils/service";
import { useGetProfile } from "../../../../UserProfileContext";
import { BsLockFill } from "react-icons/bs";
import { formatterNumber } from "../../../../utils/input-number";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const { SubMenu } = Menu;

export default function MenuItem(props) {
  const dataProfile = useGetProfile();
  const { onClose } = props;
  const history = useHistory();

  // Redux
  const customise = useSelector((state) => state.customise);

  const [ticketData, setTicketData] = useState(0);

  const getTicketSummary = async () => {
    const res = await getAll(API_URL.ticket + "/indicator");
    if (res?.status === 200) {
      setTicketData(res?.data?.data);
    }
  };

  const [subsPlan, setSubsPlan] = useState(null);
  const getSubsPlan = async () => {
    var res = await getAll(API_URL.panel + "/subscription-plan");
    if (res?.status === 200) {
      setSubsPlan(res.data.data);
    }
  };

  useEffect(() => {
    getTicketSummary();
    getSubsPlan();
  }, []);

  const isExpired = useMemo(() => {
    return moment(dataProfile?.panel?.expired_date).isBefore(moment());
  }, [dataProfile]);

  // Location
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // Menu
  const splitLocationUrl =
    splitLocation[splitLocation.length - 2] +
    "/" +
    splitLocation[splitLocation.length - 1];

  const toLink = (item) => {
    if (item?.canLock) if (isExpired) return "#";
    return item.navLink;
  };

  const [modalLock, setModalLock] = useState(false);
  const clicked = (item) => {
    if (item?.canLock && isExpired) setModalLock(true);
    onClose();
  };

  const renderSidebar = (navMenu) => {
    return navMenu.map((item, index) => {
      if (item.header) {
        return (
          <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>
        );
      }

      if (item.children && !item.icon)
        return (
          <Menu.ItemGroup key={item.id} id={item.id} title={item.title}>
            {renderSidebar(item.children)}
          </Menu.ItemGroup>
        );

      if (item.children) {
        return (
          <SubMenu key={item.id} icon={item.icon} title={item.title}>
            {item.children.map((childrens, index) => {
              if (!childrens.children) {
                const childrenNavLink = childrens.navLink.split("/");

                return (
                  // Level 2
                  <Menu.Item
                    key={childrens.id}
                    id={childrens.id}
                    className={
                      splitLocationUrl ===
                      childrenNavLink[childrenNavLink.length - 2] +
                        "/" +
                        childrenNavLink[childrenNavLink.length - 1]
                        ? "ant-menu-item-selected"
                        : "ant-menu-item-selected-in-active"
                    }
                    onClick={() => clicked(childrens)}
                  >
                    <Link to={() => toLink(childrens)}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          paddingLeft: "26px",
                        }}
                      >
                        <div>{childrens.title}</div>

                        <div>
                          {childrens.canLock && isExpired && <BsLockFill />}
                        </div>
                      </div>
                    </Link>
                  </Menu.Item>
                );
              } else {
                return (
                  // Level 3
                  <SubMenu key={childrens.id} title={childrens.title}>
                    {childrens.children.map((childItem, index) => {
                      const childrenItemLink = childItem.navLink.split("/");

                      return (
                        <Menu.Item
                          key={childItem.id}
                          id={childItem.id}
                          className={
                            splitLocationUrl ===
                            childrenItemLink[childrenItemLink.length - 2] +
                              "/" +
                              childrenItemLink[childrenItemLink.length - 1]
                              ? "ant-menu-item-selected"
                              : "ant-menu-item-selected-in-active"
                          }
                          onClick={() => clicked(childItem)}
                        >
                          <Link
                            style={{ paddingLeft: "26px" }}
                            to={() => toLink(childItem)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "start",
                                paddingLeft: "26px",
                              }}
                            >
                              <div>{childItem.title}</div>
                              <div>
                                {childItem.canLock && isExpired && (
                                  <BsLockFill />
                                )}
                              </div>
                            </div>{" "}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
            })}
          </SubMenu>
        );
      } else {
        const itemNavLink = item.navLink.split("/");

        if (!item.checkTier || dataProfile?.is_referral_feature === true) {
          return (
            // Level 1
            <Menu.Item
              key={item.id}
              icon={item.icon}
              id={item.id}
              onClick={() => clicked(item)}
              className={
                splitLocation[splitLocation.length - 2] +
                  "/" +
                  splitLocation[splitLocation.length - 1] ===
                itemNavLink[itemNavLink.length - 2] +
                  "/" +
                  itemNavLink[itemNavLink.length - 1]
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-selected-in-active"
              }
            >
              {item.indicator ? (
                <>
                  <div className="vc-d-flex vc-d-flex-between vc-align-items-center">
                    <Link to={() => toLink(item)}>{item.title}</Link>

                    {item.indicator === "ticket-indicator" && (
                      <Badge count={ticketData} className="vc-ml-8" />
                    )}
                  </div>
                </>
              ) : (
                <Link to={() => toLink(item)}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "start",
                    }}
                  >
                    <div>{item.title}</div>
                    <div>{item.canLock && isExpired && <BsLockFill />}</div>
                  </div>
                </Link>
              )}
            </Menu.Item>
          );
        }
      }
    });
  };

  const getSidebarType = () => {
    let sidebarMenu = [];
    let navMenu = null;
    let isPanel = false;

    // if (localStorage.getItem("vcg-sidebar-type") === "panel") {
    //   navMenu = navigationPanel;
    // } else {
    //   navMenu = navigation;
    // }

    if (location.pathname.startsWith("/panel")) {
      navMenu = navigationPanel;
      isPanel = true;
    } else {
      navMenu = navigation;
    }

    return renderSidebar(navMenu);
  };

  let menuItem = getSidebarType();

  const menuHeight = useMemo(() => {
    if (!dataProfile?.tier_has_panel) return "calc(100vh - 150px)";
    if (pathname?.includes("/panel")) return "calc(100vh - 250px)";
    return "calc(100vh - 240px)";
  }, [pathname, dataProfile]);

  return (
    <>
      <Menu
        mode="inline"
        defaultOpenKeys={[
          splitLocation.length === 5
            ? splitLocation[splitLocation.length - 3]
            : null,
          splitLocation[splitLocation.length - 2],
        ]}
        theme={customise.theme == "light" ? "light" : "dark"}
        style={{
          minHeight: menuHeight,
          maxHeight: menuHeight,
          paddingBottom: "24px",
        }}
      >
        {menuItem}
      </Menu>
      <Modal
        title={
          <h4 className="vc-mb-0 vc-text-center vc-text-color-primary-1">
            <BsLockFill /> Akses Dibatasi
          </h4>
        }
        open={modalLock}
        onCancel={() => setModalLock(false)}
        footer={null}
      >
        <p className="vc-text-center vc-p-0 vc-m-0">
          Segera topup saldo Mitra untuk memperpanjang masa penggunaan panel dan
          website topup
        </p>
        <div className="vc-px-16 vc-py-8 vc-bg-info-4 vc-my-16 vc-d-flex vc-justify-content-between vc-w-100 vc-border-radius">
          <p className="vc-w-100 vc-p-0 vc-m-0">Biaya Perpanjangan</p>
          <p className="vc-w-100 vc-text-right vc-p-0 vc-m-0 vc-font-weight-600 vc-text-color-info-1">
            Rp {formatterNumber(subsPlan?.price)}
          </p>
        </div>
        <p className="vc-text-center">
          Saldo Mitra yang cukup akan otomatis terpotong untuk perpanjangan
        </p>
        <Button
          type="primary"
          className="vc-w-100"
          onClick={() => {
            setModalLock(false);
            history.push("/topup/data");
          }}
        >
          Top Up Saldo Mitra
        </Button>
      </Modal>
    </>
  );
}
