import React, { useState } from "react";
import { RiStore2Line } from "react-icons/ri";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGetProfile } from "../../../UserProfileContext";
import { Button, Modal } from "antd";

function BuatWebsiteButton({ collapsed }) {
  const history = useHistory();
  const dataProfile = useGetProfile();

  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          if (dataProfile?.key_verified || dataProfile?.kyc_data) {
            history.push("/mitra-panel/register");
            return;
          }
          setOpen(true);
        }}
        className="vc-p-8 vc-d-flex vc-d-flex-justify-center vc-bg-color-primary-1 vc-align-items-center vc-cursor-pointer"
        style={{ borderRadius: "8px", color: "white" }}
      >
        <RiStore2Line className="remix-icon " />
        {!collapsed && (
          <div className="vc-mt-2 vc-ml-4" style={{ fontSize: "14px" }}>
            {" "}
            Buat Website
          </div>
        )}
      </div>
      <Modal
        title={
          <h4 className="vc-mb-0 vc-text-center vc-text-color-primary-1">
            Kelengkapan Berkas Dibutuhkan
          </h4>
        }
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <p className="vc-text-center">
          Untuk bisa membuat Website Topup kamu perlu{" "}
          <strong>melengkapi berkas</strong> terlebih dahulu
        </p>

        <Button
          type="primary"
          className="vc-w-100"
          onClick={() => {
            setOpen(false);
            history.push("/kyc");
          }}
        >
          Lengkapi Berkas Sekarang
        </Button>
      </Modal>
    </>
  );
}

export default BuatWebsiteButton;
