import { Suspense, useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../redux/customise/customiseActions";

// Router
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";
// Routes
import { Routes } from "./routes";

// Layouts
import VerticalLayout from "../layout/VerticalLayout";
import FullLayout from "../layout/FullLayout";

// Components
import Dashboard from "../view/pages/dashboard";
import KYCVerified from "../view/pages/kyc";
import Error400 from "../view/pages/error/400";
import Error401 from "../view/pages/error/401";
import Error403 from "../view/pages/error/403";
import Error404 from "../view/pages/error/404";
import Error500 from "../view/pages/error/500";

import { autoLogout } from "../utils/logout";
import { isBrowser } from "react-device-detect";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BlockPanel from "../layout/BlockPanel";
import NotAvailablePanel from "../layout/NotAvailablePanel";

export default function Router() {
  // Redux
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();
  // Location
  const location = useHistory();

  // Dark Mode
  useEffect(() => {
    document.querySelector("body").classList.add(customise.theme);
    dispatch(theme(customise.theme));
  }, []);

  // RTL
  useEffect(() => {
    if (customise.direction == "ltr") {
      document.querySelector("html").setAttribute("dir", "ltr");
    } else if (customise.direction == "rtl") {
      document.querySelector("html").setAttribute("dir", "rtl");
    }
  }, []);

  // All of the available layouts
  const Layouts = { VerticalLayout, FullLayout };

  // Return Filtered Array of Routes & Paths
  const LayoutRoutesAndPaths = (layout) => {
    const LayoutRoutes = [];
    const LayoutPaths = [];
    if (Routes) {
      // Checks if Route layout or Default layout matches current layout
      Routes.filter(
        (route) =>
          route.layout === layout &&
          (LayoutRoutes.push(route), LayoutPaths.push(route.path))
      );
    }

    return { LayoutRoutes, LayoutPaths };
  };

  // Return Route to Render
  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);
      let LayoutTag = Layouts[layout];
      return (
        <Route path={LayoutPaths} key={index}>
          <LayoutTag>
            <Switch>
              {LayoutRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact === true}
                    render={(props) => {
                      return (
                        <Suspense fallback={null}>
                          <>
                            {route?.blockWhenExpired === true && <BlockPanel />}
                            {route?.type === "panel" && !isBrowser && (
                              <NotAvailablePanel />
                            )}
                            {route.auth === true ? (
                              <Auth kyc={route.kyc}>
                                {isBrowser ? (
                                  <route.component {...props} />
                                ) : (
                                  <route.mobile {...props} />
                                )}
                              </Auth>
                            ) : (
                              <>
                                {isBrowser ? (
                                  <route.component {...props} />
                                ) : (
                                  <route.mobile {...props} />
                                )}
                              </>
                            )}
                          </>
                        </Suspense>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </LayoutTag>
        </Route>
      );
    });
  };

  return (
    <BrowserRouter>
      <Switch>
        {ResolveRoutes()}

        <Route
          key={"/"}
          path={"/"}
          exact
          render={() => {
            return <Redirect to="/dashboard" />;
          }}
        />

        {/* NotFound */}
        <Route path="/401">
          <Error401 />
        </Route>
        <Route path="/403">
          <Error403 />
        </Route>
        <Route path="/400">
          <Error400 />
        </Route>
        <Route path="/500">
          <Error500 />
        </Route>
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function Auth(props) {
  const { kyc, children } = props;
  const location = useLocation();

  const history = useHistory();

  const state = location.state;

  const storage = localStorage.getItem("loginSession");
  const emailStorage = localStorage.getItem("sendEmailVerif");

  if (storage) {
    const data = JSON.parse(storage);
    const token = data.accessToken;
    const phoneStatus = data.phoneStatus;
    const emailStatus = data.emailStatus;

    if (token === null) {
      autoLogout();
    }

    if (phoneStatus === false || emailStatus === false) {
      if (phoneStatus === false) {
        window.location.href = "/verify-phone";
      } else {
        if (emailStorage !== null && emailStatus === false) {
          window.location.href = "/verify-email-info";
        } else if (emailStatus === false && data.authType === "phone") {
          window.location.href = "/verify-email";
        } else {
          autoLogout();
        }
      }
    }
  } else {
    autoLogout();
  }

  return children;
}
