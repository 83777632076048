import React from "react";

import { Col, Drawer, Row } from "antd";
import { RiCloseLine, RiStore2Line } from "react-icons/ri";

import MenuLogo from "../logo";
import MenuItem from "../item";
import MenuFooter from "../footer";
import { useGetProfile } from "../../../../UserProfileContext";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MenuHeader from "../header";
import BuatWebsiteButton from "../BuatWebsiteButton";

export default function MenuMobile(props) {
  const { onClose, visible } = props;
  const history = useHistory();

  // Profile
  const dataProfile = useGetProfile();

  return (
    <Drawer
      title={<MenuLogo onClose={onClose} />}
      className="vc-mobile-sidebar"
      placement="left"
      closable={true}
      onClose={onClose}
      visible={visible}
      closeIcon={
        <RiCloseLine className="remix-icon vc-text-color-black-80" size={20} />
      }
    >
      <div className="vc-pb-8">
        <MenuHeader />
      </div>
      <MenuItem onClose={onClose} />
      {dataProfile && dataProfile?.tier_has_panel && (
        <>
          {dataProfile.panel !== null ? (
            dataProfile?.panel?.status === 2 && (
              <MenuFooter onClose={onClose} collapsed={false} />
            )
          ) : (
            <Row
              className="vc-sidebar-footer vc-pb-24 vc-px-24 vc-bg-color-dark-100 vc-py-16"
              align="middle"
              justify="space-between"
              style={{
                backgroundColor: "#7750F7A",
                position: "sticky",
                bottom: 0,
              }}
            >
              <Col span={24}>
                <BuatWebsiteButton />
                <div
                  style={{ fontWeight: 600, fontSize: "12px" }}
                  className="vc-text-color-dark-40 vc-text-center vc-mt-6"
                >
                  Website:{" "}
                  <strong className="vc-text-color-danger-1">
                    Tidak Active
                  </strong>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
    </Drawer>
  );
}
