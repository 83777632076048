import React, { useEffect, useState } from "react";

import { Col, Row, Button, Alert, Breadcrumb } from "antd";
import { ArrowLeft } from "react-iconly";
import { getAll } from "../../../../utils/service";
import { API_URL } from "../../../../api/config";
import { Link } from "react-router-dom";
import themeConfig from "../../../../configs/themeConfig";
import { BrowserView } from "react-device-detect";
import { useGetProfile } from "../../../../UserProfileContext";

export default function PageTitle(props) {
  const { pageTitle, pageText, backButton, kycVerified, children } = props;

  // Profile
  const dataProfile = useGetProfile();

  return (
    <BrowserView>
      <Row gutter={[32, 32]} className="vc-mb-16">
        <Col span={24}>
          {/* {kycVerified === true &&
          dataProfile?.kyc_verified === false &&
          dataProfile?.kyc_pending === false &&
          dataProfile?.kyc_reject === false ? (
            <Row>
              <Col span={24}>
                <Alert
                  description={
                    <>
                      <p className="vc-mb-0">
                        Maaf kamu belum bisa melakukan transaksi. Silahkan
                        lengkapi KYC untuk melakukan transaksi game,{" "}
                        <span>
                          <Link
                            to="/kyc-form"
                            className="vc-font-weight-600 vc-text-color-danger-1"
                          >
                            Klik Disini
                          </Link>
                        </span>
                      </p>
                    </>
                  }
                  type="error"
                  className="vc-mb-16 vc-p-12 vc-w-100"
                />
              </Col>
            </Row>
          ) : kycVerified === true &&
            dataProfile?.kyc_verified === false &&
            dataProfile?.kyc_pending === true &&
            dataProfile?.kyc_reject === false ? (
            <Row>
              <Col span={24}>
                <Alert
                  description={
                    <>
                      <p className="vc-mb-0">
                        Saat ini kami sedang melakukan review terhadap KYC yang
                        telah kamu input. Jika KYC belum segera direview
                        silahkan hubungi kami{" "}
                        <a
                          href={"https://wa.me/" + themeConfig.whatsapp}
                          target="_blank"
                          className="vc-font-weight-600 vc-text-color-info-1"
                        >
                          Klik Disini
                        </a>
                      </p>
                    </>
                  }
                  className="vc-mb-16 vc-p-12 vc-w-100"
                />
              </Col>
            </Row>
          ) : kycVerified === true &&
            dataProfile?.kyc_verified === false &&
            dataProfile?.kyc_pending === false &&
            dataProfile?.kyc_reject === true ? (
            <Row>
              <Col span={24}>
                <Alert
                  description={
                    <>
                      <p className="vc-mb-0">
                        Maaf dokumen KYC tidak dapat disetujui{" "}
                        <span>
                          <Link
                            to="/kyc"
                            className="vc-font-weight-600 vc-text-color-danger-1"
                          >
                            Lihat Detail
                          </Link>
                        </span>
                      </p>
                    </>
                  }
                  type="error"
                  className="vc-mb-16 vc-p-12 vc-w-100"
                />
              </Col>
            </Row>
          ) : (
            ""
          )} */}
          <Row>
            {backButton && (
              <Col>
                <Button
                  type="text"
                  onClick={backButton}
                  shape="circle"
                  className="vc-text-color-black-100 vc-mr-8 vc-text-color-dark-10"
                >
                  <ArrowLeft set="light" />
                </Button>
              </Col>
            )}
            <Col flex="auto">
              {pageTitle && <h2 className="vc-mb-0">{pageTitle}</h2>}
              {pageText && (
                <p className="vc-mb-0 vc-mb-sm-16 vc-p1-body">{pageText}</p>
              )}
            </Col>

            {children && (
              <Col flex="auto" align="right">
                <Row span={24} justify="end">
                  {children}
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </BrowserView>
  );
}
