import React, { useState, createElement, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFoldLine, RiMenuUnfoldLine, RiStore2Line } from "react-icons/ri";

import logoSmall from "../../../assets/images/logo/logo-small.webp";

import MenuLogo from "./logo";
import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";
import { useGetProfile } from "../../../UserProfileContext";
import MenuHeader from "./header";
import BuatWebsiteButton from "./BuatWebsiteButton";

const { Sider } = Layout;

export default function Sidebar(props) {
  const { visible, setVisible } = props;

  // Redux
  const customise = useSelector((state) => state.customise);

  // Collapsed
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (customise.sidebarCollapsed) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [customise]);

  // Location
  const location = useLocation();
  const { pathname } = location;

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };

  // Menu
  function toggle() {
    setCollapsed(!collapsed);
  }

  const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
    className: "trigger",
    onClick: toggle,
  });

  // Profile
  const dataProfile = useGetProfile();

  return (
    <Sider
      id="vc-sidebar-component"
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={256}
      className="vc-sidebar vc-bg-color-black-0 vc-bg-color-dark-100"
    >
      <Row
        className="vc-mr-12 vc-ml-24 vc-mt-24"
        align="bottom"
        justify="space-between"
      >
        <Col>{collapsed === false ? <MenuLogo onClose={onClose} /> : ""}</Col>

        {customise.sidebarCollapseButton && (
          <Col className="vc-pr-0">
            <Button
              icon={trigger}
              type="text"
              className="vc-float-right vc-text-color-dark-0"
            ></Button>
          </Col>
        )}

        {collapsed !== false && (
          <Col className="vc-mt-8">
            <Link to="/dashboard" onClick={onClose}>
              <img className="vc-logo" src={logoSmall} alt="logo" />
            </Link>
          </Col>
        )}
      </Row>
      <div className="vc-mt-24">
        <MenuHeader collapsed={collapsed} />
      </div>
      <MenuItem onClose={onClose} />
      {dataProfile && dataProfile?.tier_has_panel && (
        <>
          {dataProfile.panel !== null ? (
            dataProfile?.panel?.status === 2 && (
              <MenuFooter onClose={onClose} collapsed={collapsed} />
            )
          ) : (
            <Row
              className="vc-sidebar-footer vc-pb-24 vc-px-24 vc-bg-color-dark-100 vc-py-16"
              align="middle"
              justify="space-between"
              style={{
                backgroundColor: "#7750F7A",
                position: "sticky",
                bottom: 0,
              }}
            >
              <Col span={24}>
                <BuatWebsiteButton collapsed={collapsed} />
                {!collapsed && (
                  <div
                    style={{ fontWeight: 600, fontSize: "12px" }}
                    className="vc-text-color-dark-40 vc-text-center vc-mt-6"
                  >
                    Website:{" "}
                    <strong className="vc-text-color-danger-1">
                      Tidak Active
                    </strong>
                  </div>
                )}
              </Col>
            </Row>
          )}
        </>
      )}

      <MenuMobile onClose={onClose} visible={visible} />
    </Sider>
  );
}
