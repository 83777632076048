import {
  Bag2,
  Bookmark,
  Category,
  Graph,
  Message,
  Paper,
  Plus,
  Star,
  Ticket,
  TimeSquare,
  User,
  Wallet,
  Work,
} from "react-iconly";

const pages = [
  {
    header: "UTAMA",
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/dashboard",
  },
  {
    id: "statistic",
    title: "Statistik",
    icon: <Graph set="curved" className="remix-icon" />,
    navLink: "/statistic",
  },
  {
    id: "product",
    title: "Produk",
    icon: <Bag2 set="curved" className="remix-icon" />,
    navLink: "/product/data",
  },
  {
    id: "transaction",
    title: "Transaksi",
    icon: <Bookmark set="curved" className="remix-icon" />,
    navLink: "/list-transaction",
  },
  {
    id: "topup",
    title: "Top Up Saldo",
    icon: <Plus set="curved" className="remix-icon" />,
    navLink: "/topup/data",
  },
  {
    id: "history-balance",
    title: "Riwayat Saldo",
    icon: <Wallet set="curved" className="remix-icon" />,
    navLink: "/history-balance",
  },
  {
    id: "referral",
    title: "Referral",
    icon: <Star set="curved" />,
    navLink: "/referral/data",
    checkTier: true,
  },
  // {
  //   id: "pricelist",
  //   title: "Pricelist",
  //   icon: <Paper set="curved" />,
  //   children: [
  //     {
  //       id: "pricelist",
  //       title: "Produk Rekomendasi",
  //       navLink: "/best-price",
  //     },
  //     {
  //       id: "pricelist",
  //       title: "Semua Produk",
  //       navLink: "/pricelist",
  //     },
  //   ],
  // },
  {
    id: "selling-tools",
    title: "Tools",
    icon: <Work set="curved" />,
    children: [
      {
        id: "price-history",
        title: "Riwayat Harga",
        navLink: "/price-history/data",
      },
      {
        id: "set-selling-price",
        title: "Atur Harga Jual",
        navLink: "/set-selling-price/product",
      },
      {
        id: "pembukuan",
        title: "Pembukuan",
        navLink: "/pembukuan",
      },
      {
        id: "api-integrations",
        title: "Integrasi API",
        navLink: "/setting/api-integration",
      },
    ],
  },
  {
    header: "LAINNYA",
  },
  {
    id: "request-ticket",
    title: "Permintaan Tiket",
    icon: <Ticket set="curved" className="remix-icon" />,
    indicator: "ticket-indicator",
    navLink: "/request-ticket/data",
  },
  {
    id: "feedback",
    title: "Kritik dan Saran",
    icon: <Message set="curved" className="remix-icon" />,
    navLink: "/feedback",
  },
  {
    id: "activity-log",
    title: "Log Aktifitas",
    icon: <TimeSquare set="curved" className="remix-icon" />,
    navLink: "/activity-log",
  },
  {
    id: "profile",
    title: "Profil Saya",
    icon: <User set="curved" className="remix-icon" />,
    navLink: "/profile",
  },
];

export default pages;
