import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Divider, Row, Modal, Tag } from "antd";

import avatarImg from "../../../assets/images/default-profile.svg";
import { autoLogout } from "../../../utils/logout";
import { Call, Edit, Home, Send } from "react-iconly";
import themeConfig from "../../../configs/themeConfig";
import getInitials from "../../../utils/getInitials";

const { confirm } = Modal;

export default function HeaderUser({ name = "", tier, panel, panelStatus }) {
  const handleLogout = () => {
    confirm({
      title: (
        <h5 className="vc-mb-0 vc-font-weight-500">
          Apakah Anda yakin akan Logout?
        </h5>
      ),
      icon: false,
      okText: "Logout",
      cancelText: "Batal",
      onOk: () => {
        autoLogout();
      },
    });
  };

  const menu = (
    <div
      className="vc-border-radius vc-border-1 vc-border-color-black-40 vc-bg-black-0 vc-bg-dark-100 vc-border-color-dark-80 vc-p-24 vc-mt-12"
      style={{ width: 260 }}
    >
      <span className="vc-d-block h5 vc-text-color-black-100 vc-text-color-dark-0 vc-mb-8">
        Hello <span style={{ textTransform: "capitalize" }}>{name}</span>
      </span>

      <Link
        to="/setting/account-setting"
        className="vc-p1-body vc-text-color-primary-1 vc-text-color-dark-primary-2 vc-hover-text-color-primary-2"
      >
        Pengaturan Akun
      </Link>

      <Divider className="vc-my-8" />

      <Row>
        <Col span={24}>
          <Link
            to="/dashboard"
            className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Home set="curved" size={16} />

            <span className="vc-ml-8">Beranda</span>
          </Link>
        </Col>
        <Col span={24}>
          <a
            href={"https://wa.me/" + themeConfig.whatsapp}
            target="_blank"
            className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <Call set="curved" size={16} />

            <span className="vc-ml-8">Hubungi Admin</span>
          </a>
        </Col>
        {panel || panelStatus ? (
          <>
            {panel?.status === 2 ? (
              <Col span={24}>
                <Link
                  to="/mitra-panel/status"
                  className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
                  style={{ marginLeft: -10, marginRight: -10 }}
                >
                  <Send set="curved" size={16} />

                  <span className="vc-ml-8">Buka Website</span>
                </Link>
              </Col>
            ) : panel?.status === 1 ? (
              <Col span={24}>
                <Link
                  to="/mitra-panel/status"
                  className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
                  style={{ marginLeft: -10, marginRight: -10 }}
                >
                  <Edit set="curved" size={16} />

                  <span className="vc-ml-8">Buat Website</span>
                  <Tag className="vc-ml-8" color="warning">
                    Pending
                  </Tag>
                </Link>
              </Col>
            ) : panel?.status === 3 ? (
              <Col span={24}>
                <Link
                  to="/mitra-panel/status"
                  className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
                  style={{ marginLeft: -10, marginRight: -10 }}
                >
                  <Edit set="curved" size={16} />

                  <span className="vc-ml-8">Buat Website</span>
                  <Tag className="vc-ml-8" color="error">
                    Rejected
                  </Tag>
                </Link>
              </Col>
            ) : (
              <Col span={24}>
                <Link
                  to="/mitra-panel/register"
                  className="vc-d-flex-center vc-p1-body vc-py-8 vc-px-10 vc-d-block vc-transition vc-hover-bg-primary-4 vc-hover-bg-dark-80 vc-border-radius"
                  style={{ marginLeft: -10, marginRight: -10 }}
                >
                  <Edit set="curved" size={16} />

                  <span className="vc-ml-8">Buat Website</span>
                </Link>
              </Col>
            )}
          </>
        ) : (
          <></>
        )}
      </Row>

      <Divider className="vc-my-8" />

      <a
        onClick={() => {
          handleLogout();
        }}
        className="vc-p1-body vc-text-color-danger-1"
      >
        Logout
      </a>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar size={40} className="vc-cursor-pointer vc-text-uppercase">
          {getInitials(name)}
        </Avatar>
      </Dropdown>
    </Col>
  );
}
