import React, { Suspense, useEffect, useRef, useState } from "react";

import { Card, Col, Row, Button, Form, Skeleton, Image, Tooltip } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";

import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCheckboxCircleFill,
  RiInformationLine,
} from "react-icons/ri";
import { getAll, getPaginate } from "../../../utils/service";
import { API_URL } from "../../../api/config";
import { Link, useHistory } from "react-router-dom";
import SingleTransaction from "../../../layout/components/wmr/transaction";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SingleProduct from "../../../layout/components/wmr/product";
import { formatterNumber } from "../../../utils/input-number";
import { useGetProfile } from "../../../UserProfileContext";
import DriverJS from "../../../utils/coachmark/web/driver";
import HomeCoachmark from "../../../utils/coachmark/web/home.json";
import HomeCoachmarkUnverified from "../../../utils/coachmark/web/home-unverified.json";
import register from "../../../assets/images/vicimon2.png";

export default function Home() {
  const [form] = Form.useForm();
  const history = useHistory();

  // Profile
  const dataProfile = useGetProfile();
  const [isCompleteMilestone, setIsCompleteMilestone] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleCheckMilestone = () => {
    var total = 1;
    if (dataProfile?.has_pin) total += 1;
    if (dataProfile?.kyc_verified) total += 1;
    if (dataProfile?.has_topup) total += 1;
    if (dataProfile?.has_purchase) total += 1;

    setProgress((total / 5) * 100);

    if (
      dataProfile?.has_pin === true &&
      // dataProfile?.kyc_verified === true &&
      dataProfile?.has_topup === true &&
      dataProfile?.has_purchase === true
    ) {
      DriverJS("dashboard", HomeCoachmark, 0);

      setIsCompleteMilestone(true);
      getDataTransaksi();
      getTopProduct();
      getPendingTopup();
    } else {
      setIsCompleteMilestone(false);
      DriverJS("dashboard", HomeCoachmarkUnverified, 0);
    }
  };

  // TRANSACTION
  const [dataTransaksi, setDataTransaksi] = useState([]);

  const getDataTransaksi = async () => {
    const res = await getPaginate(API_URL.get_transaction, 10, 1, "");
    if (res?.status === 200) {
      setDataTransaksi(res?.data?.data);
    }
  };

  // TOP CATEGORY
  const carouselRef = useRef();
  const [dataTopCategory, setDataTopCategory] = useState([]);

  const getTopProduct = async () => {
    const res = await getAll(API_URL.top_product);
    if (res?.status === 200) {
      setDataTopCategory(res?.data?.data);
    }
  };

  const handleNextSlide = () => {
    carouselRef.current.next();
  };

  const handlePrevSlide = () => {
    carouselRef.current.previous();
  };

  // PENDING TOPUP
  const [dataTopUp, setDataTopUp] = useState([]);

  const carouselTopupRef = useRef();

  const handleNextSlideTopup = () => {
    carouselTopupRef.current.next();
  };

  const handlePrevSlideTopup = () => {
    carouselTopupRef.current.previous();
  };

  const getPendingTopup = async () => {
    const res = await getAll(API_URL.top_up + "/all", "?status=1");
    if (res?.status === 200) {
      setDataTopUp(res?.data?.data);
    }
  };

  useEffect(() => {
    if (dataProfile) {
      handleCheckMilestone();
    }
  }, [dataProfile]);

  useEffect(() => {
    document.title = "Dashboard - " + themeConfig.appname;
  }, []);

  const videoList = [
    {
      title: "Cara Top Up Saldo",
      url: "https://www.youtube.com/embed/zSkOCElEM5U",
      link: "https://www.youtube.com/zSkOCElEM5U",
    },
    {
      title: "Cara Beli Produk",
      url: "https://www.youtube.com/embed/eAjYPV3T5s4",
      link: "https://www.youtube.com/eAjYPV3T5s4",
    },
    {
      title: "Cara KYC",
      url: "https://www.youtube.com/embed/sjq4__3pevo",
      link: "https://www.youtube.com/sjq4__3pevo",
    },
  ];

  return (
    <>
      <PageTitle pageTitle="Dashboard" />

      <Suspense fallback={<Skeleton active />}>
        {/* {dataProfile?.panel && dataProfile?.panel?.status === 1 ? (
          <Card
            className="vc-bg-color-primary-1 vc-mb-8"
            bodyStyle={{ padding: "8px 16px", }}
          >
            <Row gutter={[16, 16]} align={"middle"}>
              <Col flex={"auto"}>
                <h5 className="vc-mb-4 vc-text-color-black-0">
                  Pendafaran pembuatan websitemu sedang diproses
                </h5>
                <p className="vc-mb-0 vc-text-color-black-0">
                  Kami sedang melakukan verifikasi terhadap berkasmu, silahkan
                  tunggu maksimal 3 hari kerja
                </p>
              </Col>
            </Row>
          </Card>
        ) : dataProfile?.panel &&
          dataProfile?.panel?.status === 2 &&
          moment().diff(dataProfile?.panel?.approved_at, "days") < 3 ? (
          <Card
            className="vc-bg-color-primary-1 vc-mb-8"
            bodyStyle={{ padding: "8px 16px", }}
          >
            <Row gutter={[16, 16]} align={"middle"}>
              <Col flex={"auto"}>
                <h5 className="vc-mb-4 vc-text-color-black-0">
                  Pendafaran pembuatan websitemu berhasil
                </h5>
                <p className="vc-mb-0 vc-text-color-black-0">
                  Silahkan buka panelmu sekarang
                </p>
              </Col>
              <Col>
                <Link to="/mitra-panel/status">
                  <a href={process.env.panel?.url} target="_blank">
                    <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                      Buka Panel
                    </Button>
                  </a>
                </Link>
              </Col>
            </Row>
          </Card>
        ) : dataProfile?.panel && dataProfile?.panel?.status === 3 ? (
          <Card
            className="vc-bg-color-primary-1 vc-mb-8"
            bodyStyle={{ padding: "8px 16px", }}
          >
            <Row gutter={[16, 16]} align={"middle"}>
              <Col flex={"auto"}>
                <h5 className="vc-mb-4 vc-text-color-black-0">
                  Maaf Pendafaran pembuatan websitemu ditolak
                </h5>
                <p className="vc-mb-0 vc-text-color-black-0">
                  Silahkan cek status penolakan dan ulangi proses penginputan
                  berkas
                </p>
              </Col>
              <Col>
                <Link to="/mitra-panel/status">
                  <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                    Lihat Status
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card>
        ) : (
          !dataProfile?.panel && (
            <Card
              className="vc-bg-color-primary-1 vc-mb-8"
              bodyStyle={{ padding: "8px 16px", }}
            >
              <Row gutter={[16, 16]} align={"middle"}>
                <Col flex={"auto"}>
                  <h5 className="vc-mb-4 vc-text-color-black-0">
                    Belum Punya Website Topup Game? Buat Websitemu Sekarang
                  </h5>
                  <p className="vc-mb-0 vc-text-color-black-0">
                    VCGamers menyediakan website topup game khusus customer
                    kamu, cukup siapkan nama store dan url websitemu
                  </p>
                </Col>
                <Col>
                  <Link to="/mitra-panel/register">
                    <Button className="vc-bg-color-black-0 vc-text-color-primary-1 vc-text-color-hover-primary-1">
                      Buat Website Sekarang
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          )
        )} */}
        {dataProfile?.kyc_verified === false &&
        dataProfile?.kyc_pending === false &&
        dataProfile?.kyc_reject === false ? (
          <Card
            bodyStyle={{ padding: "12px" }}
            className="vc-bg-color-primary-4 vc-mb-24"
          >
            <Row align={"middle"}>
              <Col span={18}>
                <div className="vc-d-flex vc-align-items-center vc-text-color-primary-1">
                  <RiInformationLine size={18} className="vc-mr-8" />
                  <p className="vc-text-color-primary-1 vc-mb-0">
                    Lakukan Verifikasi Berkas untuk bertransaksi tanpa limit
                  </p>
                </div>
              </Col>
              <Col span={6} className="vc-text-right">
                <Link to="/kyc-form">
                  <Button type="primary" className="vc-p-8">
                    Klik Disini
                  </Button>
                </Link>
              </Col>
            </Row>
          </Card>
        ) : dataProfile?.kyc_verified === false &&
          dataProfile?.kyc_pending === true &&
          dataProfile?.kyc_reject === false ? (
          <Card
            bodyStyle={{ padding: "12px" }}
            className="vc-bg-color-primary-4 vc-mb-24"
          >
            <div className="vc-d-flex">
              <div className="vc-d-flex vc-align-items-center vc-text-color-primary-1">
                <RiInformationLine size={18} className="vc-mr-8" />
                <p className="vc-text-color-primary-1 vc-mb-0">
                  Kami sedang melakukan proses pengecekan verifikasi berkas
                </p>
              </div>
            </div>
          </Card>
        ) : (
          dataProfile?.kyc_verified === false &&
          dataProfile?.kyc_pending === false &&
          dataProfile?.kyc_reject === true && (
            <Card
              bodyStyle={{ padding: "12px" }}
              className="vc-bg-color-danger-4 vc-mb-24"
            >
              <Row align={"middle"}>
                <Col span={18}>
                  <div className="vc-d-flex vc-align-items-center vc-text-color-danger-1">
                    <RiInformationLine size={18} className="vc-mr-8" />
                    <p className="vc-text-color-danger-1 vc-mb-0">
                      Maaf verifikasimu ditolak, Silahkan cek detail
                    </p>
                  </div>
                </Col>
                <Col span={6} className="vc-text-right">
                  <Link to="/kyc">
                    <Button
                      type="primary"
                      className="vc-bg-color-danger-1 vc-p-8"
                      danger
                    >
                      Lihat Detail
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          )
        )}

        {dataTopUp.length > 0 && (
          <>
            <div className="vc-d-flex vc-d-flex-between vc-mb-8">
              <h5>Selesaikan Pembayaranmu</h5>
              {dataTopUp.length > 4 && (
                <div>
                  <Button
                    type="text"
                    icon={
                      <RiArrowLeftLine
                        size={20}
                        className="vc-text-color-black-60"
                      />
                    }
                    className="vc-mr-8"
                    onClick={handlePrevSlideTopup}
                  />
                  <Button
                    type="text"
                    icon={
                      <RiArrowRightLine
                        size={20}
                        className="vc-text-color-black-60"
                      />
                    }
                    onClick={handleNextSlideTopup}
                  />
                </div>
              )}
            </div>

            <Carousel
              ref={carouselTopupRef}
              additionalTransfrom={1}
              arrows={false}
              centerMode={false}
              className="vc-w-100 vc-mb-24"
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={false}
              itemClass="vc-mr-16"
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 4,
                  partialVisibilityGutter: 32,
                },
                mobile: {
                  breakpoint: {
                    max: 667,
                    min: 0,
                  },
                  items: 4,
                  partialVisibilityGutter: 16,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 667,
                  },
                  items: 4,
                  partialVisibilityGutter: 16,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {dataTopUp?.map((item) => (
                <Card className="vc-bg-color-primary-4 vc-border-none vc-w-100 vc-mr-16">
                  <div className="vc-d-flex vc-d-flex-between vc-align-items-center">
                    <div>
                      Top Up Saldo
                      <p className="vc-text-color-black-100">
                        {item.is_admin === true
                          ? "Rp " + formatterNumber(item.amount)
                          : "Rp" + formatterNumber(item.invoice_paid_amount)}
                      </p>
                    </div>
                    <div>
                      <Link to={"/topup/payment?q=" + item.id}>
                        <Button type="primary">Detail</Button>
                      </Link>
                    </div>
                  </div>
                </Card>
              ))}
            </Carousel>
          </>
        )}

        {isCompleteMilestone === true ? (
          <>
            {dataTopCategory.length > 0 && (
              <div id="vc-top-sale-product-component">
                <div className="vc-d-flex vc-d-flex-between vc-mb-8">
                  <h5>Yang sering kamu beli 👜</h5>
                  {dataTopCategory.length > 6 && (
                    <div>
                      <Button
                        type="text"
                        icon={
                          <RiArrowLeftLine
                            size={20}
                            className="vc-text-color-black-60"
                          />
                        }
                        className="vc-mr-8"
                        onClick={handlePrevSlide}
                      />
                      <Button
                        type="text"
                        icon={
                          <RiArrowRightLine
                            size={20}
                            className="vc-text-color-black-60"
                          />
                        }
                        onClick={handleNextSlide}
                      />
                    </div>
                  )}
                </div>

                <Carousel
                  ref={carouselRef}
                  additionalTransfrom={1}
                  arrows={false}
                  centerMode={false}
                  className="vc-w-100 vc-mb-24"
                  containerClass="container"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite={false}
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1024,
                      },
                      items: 6,
                      partialVisibilityGutter: 32,
                    },
                    mobile: {
                      breakpoint: {
                        max: 667,
                        min: 0,
                      },
                      items: 4,
                      partialVisibilityGutter: 16,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 667,
                      },
                      items: 6,
                      partialVisibilityGutter: 16,
                    },
                  }}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {dataTopCategory?.map((item) => (
                    <SingleProduct
                      key={"top-" + item.parent_category_id}
                      id={item.parent_category_id}
                      logo={item.image}
                      name={item.name}
                      className="vc-mr-8"
                    />
                  ))}
                </Carousel>
              </div>
            )}

            {dataTransaksi.length > 0 && (
              <div id="vc-top-trx-component">
                <h5 className="vc-mb-8">Transaksi Terakhir</h5>
                {dataTransaksi?.map((item, index) => (
                  <SingleTransaction
                    index={index}
                    id={item.id}
                    key={item.id}
                    image={item.product_image}
                    date={item.date}
                    trx={item.code}
                    product={item.product_name}
                    sku={item.item_name}
                    price={item.selling_total}
                    status={item.status}
                    refund={item.refunded}
                    parentCategory={item.parent_category_id}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          isCompleteMilestone === false && (
            <Row gutter={[16, 16]}>
              <Col span={24} lg={12}>
                <Card
                  className="vc-border-color-dark-10 vc-h-100"
                  style={{
                    padding: "0px",
                    margin: "0px",
                    position: "relative",
                  }}
                >
                  <div
                    className="vc-px-16"
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg, #6238D3 0%, #B278FD 75%, #6238D3 100%)",
                      margin: "-24px",
                      display: "flex",
                      justifyContent: "between",
                      position: "relative",
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    <div
                      className="vc-w-100 "
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <h5
                        style={{ color: "white", fontSize: "130%" }}
                        className="vc-px-24"
                      >
                        Hai {dataProfile.name}, Salam Kenal 👋
                        <br />
                        Selamat Bergabung
                      </h5>
                    </div>
                    <Image
                      style={{
                        aspectRatio: " 16 / 14",
                        marginTop: "-24px",
                        right: "24px",
                        zIndex: 1000,
                      }}
                      preview={false}
                      src={register}
                      className="vc-border-radius vc-mx-auto"
                      width={"200px"}
                      alt={"App Icon"}
                    />
                  </div>
                  <div
                    className="vc-mt-42"
                    style={{
                      display: "flex",
                      alignItems: "between",
                      justifyContent: "between",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <div>
                      <p>
                        Saat ini kamu sudah menjadi bagian dari Mitra di
                        VCGamers! Untuk bisa menikmati semua fitur dengan
                        nyaman,{" "}
                        <strong>
                          Harap melengkapi proses tahapan di samping
                        </strong>{" "}
                        agar akun kamu dapat digunakan.
                      </p>
                      <p>
                        Jika kamu memiliki kendala, bisa klik bantuan atau
                        hubungi team partnership.
                      </p>
                    </div>
                    <div
                      style={{
                        bottom: 0,
                        width: "100%",
                        display: "flex",
                        gap: "8px",
                      }}
                      className="vc-mt-48"
                    >
                      <a
                        className="vc-w-100"
                        href="https://www.vcgamers.com/about/bantuan-docs/syarat-ketentuan-mitra/"
                      >
                        <Button className="vc-w-100" type="ghost">
                          Bantuan FAQ
                        </Button>
                      </a>
                      <a
                        className="vc-w-100"
                        href={"https://wa.me/" + themeConfig.whatsapp}
                      >
                        <Button className="vc-w-100" type="ghost">
                          Customer Support
                        </Button>
                      </a>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} lg={12}>
                <Card>
                  <div id="vc-milestone-component">
                    <div className="vc-d-flex vc-d-flex-between vc-align-items-end vc-mb-2">
                      <h5 className="vc-mb-8">Selesaikan Tahap Berikut</h5>
                      <h6>
                        Perisapan{" "}
                        <span className="vc-text-color-danger-1">
                          {progress}%
                        </span>
                      </h6>
                    </div>
                    <Tooltip
                      placement="bottom"
                      title="Isi form pendaftaran dan melakukan verifikasi email serta nomer ponsel."
                    >
                      <Card
                        className="vc-mb-8 vc-bg-color-success-4 vc-bg-color-dark-success vc-text-color-success-1 vc-border-color-success-1"
                        bodyStyle={{ padding: "8px 16px" }}
                      >
                        <Row align={"middle"} justify={"space-between"}>
                          <Col>
                            <p className="vc-mb-0">Pendaftaran Akun</p>
                          </Col>
                          <Col>
                            <RiCheckboxCircleFill
                              size={18}
                              className="vc-text-color-success-1"
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title="Fitur keamanan untuk melakukan pembelian produk."
                    >
                      <Card
                        className={
                          dataProfile?.has_pin === true
                            ? "vc-mb-8 vc-bg-color-success-4 vc-bg-color-dark-success vc-text-color-success-1 vc-border-color-success-1"
                            : "vc-mb-8 vc-cursor-pointer"
                        }
                        bodyStyle={{ padding: "8px 16px" }}
                        onClick={() =>
                          dataProfile?.has_pin === false &&
                          history.push("/setting/pin")
                        }
                      >
                        <Row align={"middle"} justify={"space-between"}>
                          <Col>
                            <p className="vc-mb-0">Buat PIN</p>
                          </Col>
                          <Col>
                            {dataProfile?.has_pin === true ? (
                              <RiCheckboxCircleFill
                                size={18}
                                className="vc-text-color-success-1"
                              />
                            ) : (
                              <RiArrowRightLine
                                size={18}
                                className="vc-text-color-grey-1"
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title="Kamu bisa bebas transaksi tanpa limit."
                    >
                      <Card
                        className={
                          dataProfile?.kyc_verified === true
                            ? "vc-mb-8 vc-bg-color-success-4 vc-bg-color-dark-success vc-text-color-success-1 vc-border-color-success-1"
                            : "vc-mb-8 vc-cursor-pointer"
                        }
                        bodyStyle={{ padding: "8px 16px" }}
                        onClick={() =>
                          dataProfile?.kyc_verified === false &&
                          history.push("/kyc")
                        }
                      >
                        <Row align={"middle"} justify={"space-between"}>
                          <Col>
                            <p className="vc-mb-0">Lengkapi Berkas</p>
                          </Col>
                          <Col>
                            {dataProfile?.kyc_verified === true ? (
                              <RiCheckboxCircleFill
                                size={18}
                                className="vc-text-color-success-1"
                              />
                            ) : (
                              <RiArrowRightLine
                                size={18}
                                className="vc-text-color-grey-1"
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title="Agar akun mitramu bisa digunakan untuk membeli produk."
                    >
                      <Card
                        className={
                          dataProfile?.has_topup === true
                            ? "vc-mb-8 vc-bg-color-success-4 vc-bg-color-dark-success vc-text-color-success-1 vc-border-color-success-1"
                            : "vc-mb-8 vc-cursor-pointer"
                        }
                        bodyStyle={{ padding: "8px 16px" }}
                        onClick={() =>
                          dataProfile?.has_topup === false &&
                          history.push("/topup/form")
                        }
                      >
                        <Row align={"middle"} justify={"space-between"}>
                          <Col>
                            <p className="vc-mb-0">Top Up Saldo Pertama</p>
                          </Col>
                          <Col>
                            {dataProfile?.has_topup === true ? (
                              <RiCheckboxCircleFill
                                size={18}
                                className="vc-text-color-success-1"
                              />
                            ) : (
                              <RiArrowRightLine
                                size={18}
                                className="vc-text-color-grey-1"
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title="Kenalan dengan fitur pembelian."
                    >
                      <Card
                        className={
                          dataProfile?.has_purchase === true
                            ? "vc-mb-8 vc-bg-color-success-4 vc-bg-color-dark-success vc-text-color-success-1 vc-border-color-success-1"
                            : "vc-mb-8 vc-cursor-pointer"
                        }
                        bodyStyle={{ padding: "8px 16px" }}
                        onClick={() =>
                          dataProfile?.has_purchase === false &&
                          history.push("/product/data")
                        }
                      >
                        <Row align={"middle"} justify={"space-between"}>
                          <Col>
                            <p className="vc-mb-0">Beli Produk Pertamamu</p>
                          </Col>
                          <Col>
                            {dataProfile?.has_purchase === true ? (
                              <RiCheckboxCircleFill
                                size={18}
                                className="vc-text-color-success-1"
                              />
                            ) : (
                              <RiArrowRightLine
                                size={18}
                                className="vc-text-color-grey-1"
                              />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Tooltip>
                  </div>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <div>
                    <div className="vc-d-flex vc-d-flex-between vc-align-items-end vc-mb-2">
                      <h5 className="vc-mb-8">Pelajari Cara Penggunaan</h5>
                    </div>
                    <Row gutter={[16, 16]}>
                      {videoList.map((item) => (
                        <Col span={24} lg={8} key={item.title}>
                          <div>
                            <iframe
                              style={{
                                borderRadius: "8px",
                                height: "100%",
                                aspectRatio: "16 / 9",
                              }}
                              className="vc-w-100"
                              src={item.url}
                            ></iframe>
                          </div>
                          <Link
                            to={item.link}
                            className="vc-d-flex vc-align-items-center vc-pb-6"
                            style={{ gap: "4px" }}
                          >
                            <p className="vc-m-0 vc-p-0">Cara Top Up Saldo</p>
                            <RiArrowRightLine size={14} />
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          )
        )}
      </Suspense>
    </>
  );
}
