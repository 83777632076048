import React, { useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";

import {
  Layout,
  Button,
  Row,
  Col,
  Tag,
  Tooltip,
  Image,
  Skeleton,
  Progress,
  Modal,
  Card,
} from "antd";
import {
  RiMenuFill,
  RiCheckboxBlankCircleFill,
  RiInformationLine,
  RiErrorWarningLine,
  RiCloseLine,
  RiCheckLine,
  RiArrowRightLine,
  RiArrowRightSLine,
} from "react-icons/ri";

import HeaderUser from "./HeaderUser";
import HeaderText from "./HeaderText";
import HeaderTheme from "./HeaderTheme";
import { getAll } from "../../../utils/service";
import { API_URL, API_URL_PANEL } from "../../../api/config";
import { formatterNumber } from "../../../utils/input-number";
import { useHistory, useLocation } from "react-router-dom";
import HeaderNotifications from "./HeaderNotifications";
import { useWebSocketBalance } from "../../../WebSocketContext";
import { useGetProfile } from "../../../UserProfileContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderSearch from "./HeaderSearch";
import { Search } from "react-iconly";
import ProgressModal from "../../ProgressModal";

const { Header } = Layout;

export default function MenuHeader(props) {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { setVisible } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [currentState, setCurrentState] = useState("mitra");

  // Redux
  const customise = useSelector((state) => state.customise);

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true);

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: "start",
      });
    }, 200);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
  };

  const dataProfile = useGetProfile();

  const webSocketBalance = useWebSocketBalance();
  const [userBalance, setUserBalance] = useState(0);
  const [progress, setProgress] = useState(0);
  const [modalVisible, setModalVisible] = useState(
    localStorage.getItem("modal-progress-" + currentState) === "true"
      ? true
      : false
  );

  const getUserBalance = async () => {
    let saldo = webSocketBalance?.balance || dataProfile?.saldo;
    if (currentState === "panel") {
      let panel_saldo = 0;
      const res = await getAll(API_URL_PANEL.saldo);
      if (res?.status === 200) panel_saldo = res.data.data.panel_saldo;
      saldo = webSocketBalance?.panel_balance || panel_saldo;
    }
    setUserBalance(saldo);
  };

  // panel status
  const [panelStatus, setPanelStatus] = useState();
  const getPanelProgress = async () => {
    var res = await getAll(API_URL_PANEL.status);
    if (res?.status === 200) {
      setPanelStatus(res?.data?.data);
    }
  };

  useEffect(() => {
    getPanelProgress();
  }, [location]);

  const mitraProgress = [
    {
      text: "Pendaftaran Akun",
      status: true,
    },
    {
      text: "Buat PIN",
      status: dataProfile?.has_pin,
      link: "/setting/pin",
    },
    {
      text: "Lengkapi Berkas",
      status: dataProfile?.kyc_verified,
      link: "/kyc",
    },
    {
      text: "Top Up Saldo Pertama",
      status: dataProfile?.has_topup,
      link: "/topup/form",
    },
    {
      text: "Beli Produk Pertamamu",
      status: dataProfile?.has_purchase,
      link: "/product/data",
    },
  ];

  const panelProgress = [
    {
      text: "Aktifkan Notifikasi",
      status: Notification.permission === "granted",
      description: "Jangan lewatkan notifkasi pesanan dan lainnya",
      action: () => {
        var isGranted = Notification.permission === "granted";
        Notification.requestPermission().then(() => {
          message.success("Notifikasi diaktifkan");
          if (isGranted) window.location.reload();
        });
      },
    },
    {
      text: "Atur Tier Member",
      status: panelStatus?.is_tier_set,
      link: "/panel/setting/member-tier/data",
      description: "untuk mendapatkan perhitungan harga jual",
    },
    {
      text: "Tambah Produk",
      status: panelStatus?.is_product_set,
      link: "/panel/product-vcg/data",
      description: "Aktifkan produk yang ingin dijual",
    },
    {
      text: "Lengkapi Kontak",
      status: panelStatus?.is_profile_set,
      link: "/panel/setting/social-media",
      description: "Untuk memudahkan customer menghubungi kamu",
    },
  ];

  useEffect(() => {
    var progress = mitraProgress;
    var state = "mitra";
    if (location.pathname.includes("/panel")) {
      progress = panelProgress;
      state = "panel";
    }

    var items = progress.map((item) => item.status);
    var total = (items.filter((item) => item).length / items.length) * 100;

    setCurrentState(state);
    setProgress(total);
  }, [location, dataProfile, panelStatus]);

  useEffect(() => {
    getUserBalance();
  }, [webSocketBalance, currentState]);

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="vc-w-100 vc-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="vc-mobile-sidebar-button vc-mr-24">
          <Button
            className="vc-mobile-sidebar-button"
            type="text"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon vc-text-color-black-80 vc-text-color-dark-30"
              />
            }
          />
        </Col>

        <Col
          flex="1"
          style={{ display: !searchHeader ? "none" : "block" }}
          className={`vc-pr-md-0 vc-pr-16 vc-header-search ${
            searchActive && "vc-header-search-active"
          }`}
        >
          <HeaderSearch
            inputFocusProp={inputFocusProp}
            setSearchHeader={setSearchHeader}
          />
        </Col>

        {!searchHeader && (
          <Col flex={"auto"}>
            <HeaderText name={dataProfile?.name} />{" "}
          </Col>
        )}

        <Col>
          <Row align="middle">
            <Col className="vc-d-flex-center vc-mr-8 vc-d-sm-none">
              <div
                style={{
                  minWidth: "170px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    minWidth: "170px",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 8px",
                    height: "24px",
                    borderRadius: "7px",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalVisible(true)}
                  className={
                    progress == 100
                      ? "vc-bg-color-success-4"
                      : "vc-bg-color-danger-4"
                  }
                >
                  <p
                    className="vc-p-0 vc-m-0 vc-mr-8"
                    style={{ fontSize: "80%" }}
                  >
                    Persiapan
                  </p>
                  <Progress
                    strokeColor={{
                      "0%": progress == 100 ? "#0f0" : "#f00",
                      "100%": progress == 100 ? "#0f0" : "#f00",
                    }}
                    percent={progress}
                    size="small"
                    showInfo={false}
                  />
                  <p
                    className="vc-p-0 vc-m-0 vc-ml-8"
                    style={{
                      fontSize: "80%",
                      color: progress == 100 ? "#00b000" : "#f00",
                    }}
                  >
                    {progress}%
                  </p>
                </div>
              </div>
            </Col>
            {!searchHeader && (
              <>
                <Col className="vc-d-flex-center vc-mr-8 vc-d-sm-none">
                  <Link to="/tier">
                    <span className="vc-font-weight-600" id="vc-tier-component">
                      <Tooltip
                        className="vc-ml-4"
                        placement="bottom"
                        title="Tier ini akan di sesuaikan secara otomatis bedasarkan nilai topup deposit saldo verifikasi KYC Akun."
                      >
                        {dataProfile?.tier_image === "" ||
                        dataProfile?.tier_image === null ? (
                          <Image
                            id="response-tier-image"
                            src={
                              "https://cdn-h2h.s3.ap-southeast-1.amazonaws.com/h2h/20231015061450_652b835a31f9e42905b64128.webp"
                            }
                            width={24}
                            height={24}
                            preview={false}
                          />
                        ) : (
                          <Image
                            id="response-tier-image"
                            src={dataProfile?.tier_image}
                            width={24}
                            height={24}
                            preview={false}
                          />
                        )}
                        <span className="vc-ml-8 vc-text-color-dark-10">
                          {dataProfile?.tier_name}
                        </span>
                      </Tooltip>
                    </span>
                  </Link>
                </Col>

                <Col className="vc-d-flex-center vc-mr-8 vc-text-color-black-60 vc-d-sm-none">
                  <RiCheckboxBlankCircleFill style={{ fontSize: "4px" }} />
                </Col>
                <Col className="vc-d-flex-center vc-mr-8">
                  <span
                    className="vc-text-color-primary-1 vc-font-weight-600"
                    id="vc-amount-component"
                  >
                    {currentState == "panel" ? (
                      <Link to="/panel/saldo-point/data">
                        <span className="vc-d-sm-none">Saldo Profit </span>
                        <span
                          className={
                            userBalance > 0 ? "vc-text-color-success-1" : ""
                          }
                        >
                          Rp{formatterNumber(userBalance)}
                        </span>
                      </Link>
                    ) : (
                      <Link to="/topup/data">
                        <span className="vc-d-sm-none">Saldo </span>
                        <span
                          className={
                            userBalance > 0 ? "" : "vc-text-color-danger-1"
                          }
                        ></span>
                        Rp{formatterNumber(userBalance)}
                      </Link>
                    )}
                    {dataProfile?.kyc_verified === false &&
                      dataProfile?.maximum_unverified_amount !== 0 && (
                        <Tooltip
                          className="vc-ml-4"
                          title={
                            "Limit Rp " +
                            formatterNumber(
                              dataProfile?.maximum_unverified_amount
                            ) +
                            ", Silahkan lengkapi berkas"
                          }
                        >
                          <RiErrorWarningLine className="vc-text-color-danger-1" />
                        </Tooltip>
                      )}
                  </span>
                </Col>
              </>
            )}

            <Col className="vc-mr-sm-0 vc-mr-4 vc-d-flex-center">
              {!searchHeader ? (
                <Button
                  type="text"
                  icon={
                    <Search set="curved" className="vc-text-color-black-60" />
                  }
                  onClick={() => searchClick()}
                />
              ) : (
                <Button
                  type="text"
                  icon={
                    <RiCloseLine size={24} className="vc-text-color-black-60" />
                  }
                  onClick={() => setSearchHeader(false)}
                />
              )}
            </Col>

            <HeaderNotifications />

            <Col className="vc-d-flex-center vc-mr-4 ">
              <HeaderTheme />
            </Col>

            <HeaderUser
              name={dataProfile?.name}
              tier={dataProfile?.tier}
              panel={dataProfile?.panel}
              panelStatus={dataProfile?.tier_has_panel}
            />
          </Row>
        </Col>
      </Row>
    );
  };

  const toLink = (link) => {
    history.push(link);
    setModalVisible(false);
  };

  return (
    <Header>
      <Row justify="center" className="vc-w-100">
        {customise.contentWidth == "full" && (
          <Col span={24}>{headerChildren()}</Col>
        )}

        {customise.contentWidth == "boxed" && (
          <Col xxl={20} xl={22} span={24}>
            {headerChildren()}
          </Col>
        )}
      </Row>
      <ProgressModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </Header>
  );
}
