import { Button, Image, Modal } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import panelImage from "../assets/images/pages/panel/status.svg";

function NotAvailablePanel() {
  const [panelModal, setPanelModal] = useState(true);
  const history = useHistory();

  return (
    <>
      <Modal
        open={panelModal}
        footer={null}
        header={null}
        centered
        closable={false}
        maskStyle={{
          backdropFilter: "blur(50%)",
          filter: "blur(50%)",
          WebkitFilter: "blur(50%)",
        }}
      >
        <div className="vc-text-center vc-mb-16">
          <Image src={panelImage} />
        </div>
        <p className="vc-text-center vc-font-weight-600">
          Buat dan mengakses panel website hanya tersedia via PC/Komputer
        </p>
        <Button
          onClick={() => {
            history.push("/");
            setPanelModal(false);
          }}
          type="primary"
          className="vc-w-100"
        >
          Tutup
        </Button>
      </Modal>
    </>
  );
}

export default NotAvailablePanel;
