import {
  Bookmark,
  Category,
  Game,
  Image,
  Paper,
  Setting,
  Star,
  Ticket,
  TwoUsers,
  Wallet,
} from "react-iconly";

const pages = [
  {
    header: "UTAMA",
  },
  {
    id: "statistik",
    title: "Statistik",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/panel/statistic",
  },
  {
    id: "vc-panel-sidebar-member",
    title: "Member",
    icon: <TwoUsers set="curved" className="remix-icon" />,
    navLink: "/panel/member/data",
    canLock: true,
  },
  {
    id: "vc-panel-sidebar-transaction",
    title: "Transaksi",
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/panel/transaction/data",
  },
  {
    id: "vc-panel-sidebar-saldo-point",
    title: "Saldo Profit",
    icon: <Wallet set="curved" className="remix-icon" />,
    navLink: "/panel/saldo-point/data",
  },
  {
    id: "vc-panel-sidebar-menu",
    title: "Menu",
    children: [
      {
        id: "flash-sale",
        title: "Flash Sale",
        icon: <Star set="curved" className="remix-icon" />,
        navLink: "/panel/flash-sale/data",
        canLock: true,
      },
      {
        id: "promo",
        title: "Kode Promo",
        icon: <Ticket set="curved" className="remix-icon" />,
        navLink: "/panel/promo/data",
        canLock: true,
      },
      {
        id: "banner",
        title: "Banner",
        icon: <Image set="curved" className="remix-icon" />,
        navLink: "/panel/banner/data",
        canLock: true,
      },
      {
        id: "product",
        title: "Produk",
        icon: <Game set="curved" className="remix-icon" />,
        children: [
          {
            id: "product-vcg",
            title: "Produk Supply",
            navLink: "/panel/product-vcg/data",
            canLock: true,
          },
          {
            id: "category-label-data",
            title: "Produk Custom",
            navLink: "/panel/product-custom/data",
            canLock: true,
          },
        ],
      },
      {
        id: "category",
        title: "Kategori",
        icon: <Bookmark set="curved" className="remix-icon" />,
        children: [
          {
            id: "category-group",
            title: "Kategori",
            navLink: "/panel/category/data",
            canLock: true,
          },
          {
            id: "category-label-data",
            title: "Label Kategori",
            navLink: "/panel/category-label/data",
            canLock: true,
          },
        ],
      },
    ],
  },

  {
    header: "LAINNYA",
  },
  {
    id: "settings",
    title: "Pengaturan",
    icon: <Setting set="curved" className="remix-icon" />,
    children: [
      {
        id: "web-panel",
        title: "Konfigurasi Web",
        navLink: "/panel/setting/web-panel",
        canLock: true,
      },
      {
        id: "member-tier",
        title: "Member Tier",
        navLink: "/panel/setting/member-tier/data",
        canLock: true,
      },
      {
        id: "payment-method",
        title: "Metode Pembayaran",
        navLink: "/panel/setting/payment-method/data",
        canLock: true,
      },
      {
        id: "socmed",
        title: "Kontak & Sosial Media",
        navLink: "/panel/setting/social-media",
        canLock: true,
      },
    ],
  },
];

export default pages;
